.videoContainer {
	margin: 0;
	height: 100%;
	width: 100%;
	background: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;

	&:not(:hover) {

		.video-controls {
			opacity: 0;
		}
	}

	&:not(.playing) {

		.video-controls {
			display: none !important;
		}
	}

	.video-controls {
		transition: opacity 250ms ease;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 50px;
		position: absolute;
		bottom: 2%;
		background: transparent;

   		&[data-state=hidden] {
		   display: none;
		}

		&[data-state=visible] {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& > * {
		   width: 50px;
		   height: 100%;
		   display: inline-block;
		   padding: 0;
		   margin: 0 10px;
		}

		.progress {
			cursor: pointer;
			width: 75%;
			height: 10px;
			position: relative;

			& > * {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			span {
				width: 100%;
				height: 2px;
				vertical-align: sub;
				display: inline-block;
				background-color: $tertiaryColor;  
				border: none;
				-moz-border-radius: 4px;
				-webkit-border-radius: 4px;
				border-radius: 4px;
				padding: 3px 0;
				background-clip: content-box;
			}

			svg circle {
				fill: $tertiaryColor;
			}
		}

		.time {
			height: auto;
			color: $tertiaryColor;
		}

		button {
			border: none;
			cursor: pointer;
			background: transparent;
			background-size: contain;
			background-repeat: no-repeat;
			text-indent: -99999px;
			min-width: 50px;

			// &:focus, 
			&:hover {
				opacity: 0.5;
			}


			&[data-state="play"] {
				background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2NSA2NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjUgNjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojRDg5OTY0O3N0cm9rZS13aWR0aDoyLjY2O30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojRDg5OTY0O3N0cm9rZS1saW5lY2FwOnJvdW5kO30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzIuNiw2M2MxNi45LDAsMzAuNi0xMy43LDMwLjYtMzAuNmMwLTE2LjktMTMuNy0zMC42LTMwLjYtMzAuNlMyLDE1LjUsMiwzMi40QzIsNDkuMywxNS43LDYzLDMyLjYsNjN6Ii8+CjwvZz4KPHBvbHlnb24gY2xhc3M9InN0MSIgcG9pbnRzPSIzNy4zLDMyLjQgMjcuNCwyNi43IDI3LjQsMzguMSAiLz4KPC9zdmc+Cg==');
			}
			&[data-state="pause"] {
				background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NSA2NiI+DQoJPGRlZnM+DQoJCTxzdHlsZT4NCgkJCS5jbHMtMXtmaWxsOiNkODk5NjQ7fQ0KCQk8L3N0eWxlPg0KCTwvZGVmcz4NCgk8dGl0bGU+DQoJCVBhdXNlYnV0dG9uX2JyYXVuXzINCgk8L3RpdGxlPg0KCTxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTMyLjMyLDIuNjVBMzAuNzUsMzAuNzUsMCwxLDEsMS41OCwzMy40LDMwLjc5LDMwLjc5LDAsMCwxLDMyLjMyLDIuNjVtMC0xLjI1YTMyLDMyLDAsMSwwLDMyLDMyLDMyLDMyLDAsMCwwLTMyLTMyWiIvPg0KCTxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMjIuNiIgeT0iMTkuOTEiIHdpZHRoPSI2LjkiIGhlaWdodD0iMjYuMzUiLz4NCgk8cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjM0LjgzIiB5PSIyMC4yMiIgd2lkdGg9IjYuOSIgaGVpZ2h0PSIyNi4zNSIvPg0KPC9zdmc+');
			}
			&[data-state="mute"] {
				background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NSA2NiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNkODk5NjQ7c3Ryb2tlLXdpZHRoOjJweDt9LmNscy0xLC5jbHMtMntzdHJva2U6I2Q4OTk2NDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fS5jbHMtMntmaWxsOm5vbmU7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48dGl0bGU+U3BlYWtlcl9icmF1bl8yPC90aXRsZT48cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTIuMyAyOC4yMyAyMC40NSAyOC4yMyAyOS42NCAxOS4wNSAyOS42NCA0OS4yNyAyMC40NyA0MC4xIDEyLjMxIDQwLjAyIDEyLjMgMjguMjMiLz48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0zNy44NCwyNy4zOHM1Ljc1LDcuMzUsMCwxMy41NSIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTQ0Ljc3LDIyLjM5czcuODEsMTIuOTQsMCwyMy44NiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTUyLjMzLDE3Ljg2czcuODIsMTcuNjEsMCwzMi40NyIvPjwvc3ZnPg==');	
			}
			&[data-state="unmute"] {
				background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NSA2NiI+DQoJPGRlZnM+DQoJCTxzdHlsZT4NCgkJCS5jbHMtMXtmaWxsOiNkODk5NjQ7c3Ryb2tlLXdpZHRoOjJweDt9DQoJCQkuY2xzLTEsDQoJCQkuY2xzLTJ7c3Ryb2tlOiNkODk5NjQ7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30NCgkJCS5jbHMtMntmaWxsOm5vbmU7c3Ryb2tlLXdpZHRoOjEuNXB4O30NCgkJPC9zdHlsZT4NCgk8L2RlZnM+DQoJPHBvbHlnb24gY2xhc3M9ImNscy0xIiBwb2ludHM9IjEyLjMgMjguMjMgMjAuNDUgMjguMjMgMjkuNjQgMTkuMDUgMjkuNjQgNDkuMjcgMjAuNDcgNDAuMSAxMi4zMSA0MC4wMiAxMi4zIDI4LjIzIi8+DQo8L3N2Zz4=');	
			}
			&[data-state="go-fullscreen"] {
				background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NSA2NiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiNkODk5NjQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjJweDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPlBsYXlidXR0b25fYnJhdW5fMjwvdGl0bGU+PHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSI1Ljk0IDI2LjYyIDUuOTQgNi42MiAyNS45NCA2LjYyIi8+PHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIzOC43IDYuNjIgNTguNyA2LjYyIDU4LjcgMjYuNjIiLz48cG9seWxpbmUgY2xhc3M9ImNscy0xIiBwb2ludHM9IjU4LjcgMzkuMjQgNTguNyA1OS4yNCAzOC43IDU5LjI0Ii8+PHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIyNS45NCA1OS4yNCA1Ljk0IDU5LjI0IDUuOTQgMzkuMjQiLz48L3N2Zz4=');	
			}
			&[data-state="cancel-fullscreen"] {
				background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NSA2NiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiNkODk5NjQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjJweDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPlBsYXlidXR0b25fYnJhdW5fMjwvdGl0bGU+PHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIyNS45NCA2LjYyIDI1Ljk0IDI2LjYyIDUuOTQgMjYuNjIiLz48cG9seWxpbmUgY2xhc3M9ImNscy0xIiBwb2ludHM9IjU4LjcgMjYuNjIgMzguNyAyNi42MiAzOC43IDYuNjIiLz48cG9seWxpbmUgY2xhc3M9ImNscy0xIiBwb2ludHM9IjM4LjcgNTkuMjQgMzguNyAzOS4yNCA1OC43IDM5LjI0Ii8+PHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSI1Ljk0IDM5LjI0IDI1Ljk0IDM5LjI0IDI1Ljk0IDU5LjI0Ii8+PC9zdmc+');	
			}
		}
	}
}