nav {

	ul {
		list-style: none;
		letter-spacing: 1.2rem;
		text-transform: uppercase;
	}
}

.menu-switcher {
	display: none;

	&:not(:checked) {
		
		& ~ .mainMenu--hidden {
			
			ul {
				transform: translateY(-6rem);
			}
		}
	}

	&:checked {
		
		& ~ .mainMenu--hidden {
			ul {
				transform: translateY(0%);
			}
		}
	}
}

.hamburgerButton {
	position: absolute;
	display: inline-block;
	cursor: pointer;
	width: 50px;
	height: 35px;
	left: 0;
	top: 1.8rem;
	z-index: 1;
	margin-left: 10px;
		
	@mixin margin($breakpoint, $contentWidth) {
		@media screen and (min-width: $breakpoint) {
			margin-left: calc((100% - #{$contentWidth}) / 2);
		}
	}
	
	@each $breakpoint, $contentWidth in $breakpointList {
		@include margin($breakpoint, $contentWidth);
	}

	span {
		position: absolute;
		width: 100%;
		left: 0;
		height: 2px;
		background: #FFFFFF;

		&:nth-child(1) {
			top: calc(6px * 1);
		}
		&:nth-child(2) {
			top: calc(6px * 3);
		}
		&:nth-child(3) {
			top: calc(6px * 5);
		}
	}

	&.hamburgerButton--brown {
		span {
			background: $textColor_0;
		}
	}
}


.mainMenu {
	position: absolute;
	transition: all 500ms ease;
	top: 0;
	left: 25%;
	z-index: 1;
	width: 75%;

	@media screen and (min-width: $breakpointL1) {
		width: 85%;
		left: 15%;
	}

	@media screen and (min-width: $breakpointL2) {
		width: calc(#{$contentWidthL2} - (50px * 2));
		left: calc(((100% - #{$contentWidthL2}) / 2) + 50px);
	}

	@media screen and (min-width: $breakpointL4) {
		width: calc(#{$contentWidthL3} - (50px * 2));
		left: calc(((100% - #{$contentWidthL3}) / 2) + 50px);
	}

	@media screen and (min-width: $breakpointL5) {
		width: calc(#{$contentWidthL3} - (50px * 2));
		left: calc(((100% - #{$contentWidthL3}) / 2) + 50px);
	}

	.flex {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-end;
		width: 100%;
		padding: 0;
		list-style-type: none;
		text-transform: uppercase;
		margin-bottom: 0;
		margin-top: 0.6rem;
		text-align: center;

		@media screen and (min-width: $breakpointL1) {
			flex-direction: row;
			margin-top: 1.7rem;
		}
			

	}
	
	li {
		margin-top: 5px;

		a {
			padding-left: 1.2rem;
			color: #ffffff;
			position: relative;

			&:hover::before {
				box-sizing: inherit;
				content: '';
				position: absolute;
				left: 0;
				bottom: -2px;
				height: 0;
				border-bottom: 1px solid transparent;
				border-bottom-color: #ffffff;
				animation-name: expandWidth;
				animation-duration: 500ms;
				animation-direction: normal;
				animation-fill-mode: forwards;
			}
		}
	}
	
	&.mainMenu--brown {
		a {
			color: $textColor_0;

			&:hover::before {
				border-bottom-color: $textColor_0;
			}
		}
	}

	&.mainMenu--episoden,
	&.mainMenu--contact {
		position: unset;
		transform: none;
		width: auto;
		min-width: unset;

		.flex {
			margin: unset;
		}
	}

	&.mainMenu--contact {

		a {
			padding-left: unset;
		}
	}

	&.mainMenu--hidden {
		overflow: hidden;
		
		ul {
			transition: all 500ms ease;
		}
	}

}

#scrollToTop {
	display: none;
	position: fixed;
	right: 10px;
	bottom: 10px;
	padding: 10px;
	line-height: 0.5;
	background-color: $primaryColor;
	opacity: 0.5;
	z-index: 100000;
	transition: opacity 500ms ease;

	&:hover {
		opacity: 1;
	}
}

#footerMenu {


	ul {

		li {

			a {

				&:hover {

				}
			}
		}
	}
}
