$activeSlideWidth: 999;
$slideContentWidth: 1920;
$slideContentHeight: 1080;

@keyframes hoverDelay {
	0% {
		pointer-events: none;
	}
	
	100% {
		pointer-events: auto;
	}
}

#headSlider {
	display: flex;
	width: 100%;
	height: 100%;
	animation-name: hoverDelay;
	animation-duration: 7s;
	animation-fill-mode: forwards;
	
	&:not(:hover) {
		
		&:not(.playing) {
			.slideContainer:first-of-type {
				flex-grow: $activeSlideWidth;

				.text {
					opacity: 0;
				}

				.mainMenu, 
				.videoInfo,
				.lc-logo {
					opacity: 1;
				}

			}	
		}	
	}

	&:not(.playing) {

		.slideContainer {
			min-width: 160px;
		}
	}

	&.playing {

		.slideContainer:not(.playing) {
			pointer-events: none;
		}
	}
		
	.slideContainer {
		flex: 1 1 auto;
		position: relative;
		background: transparent;
		overflow: hidden;
		max-width: calc(100vh / 1080 * 1920);
		transition: all 500ms ease;

		@media not all and (max-aspect-ratio: 16/9) {

			&:nth-of-type(2):not(.playing) {

				video {
					max-width: none;
				}

				&:not(:hover) {

					video {
						transition: all 500ms ease;
						// transform: translateX(-65%);
						right: calc(1vh / 1080 * 1920 * (-25));
					}
				}

				&:hover {

					video {
						transition: all 500ms ease;
						// transform: translateX(0%);
						right: 0;
					}
				}
			}
		}

		&::after {
			content: '';
			display: block;
			pointer-events: none;
			position: absolute;
		    width: 100%;
		    height: 100%;
		    box-shadow: inset 7px 0px 10px -6px rgba(0, 0, 0, 0.75);
		    top: 0;
		    left: 0;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, transparent 50%, transparent 100%);
		}
		
		&:hover {
			flex-grow: $activeSlideWidth;

			.videoContainer ~ .text,
			video ~ .text {
				opacity: 0;
			}

			img ~ .text {
				background: rgba(255,255,255,0);

				p {
					transform: translate(-50%, -50%) rotate(0deg);
					left: 50%;
					font-size: 1.5rem;
					line-height: 1.5rem;
					text-align: center;

					span:first-child::after {
						width: 50%;
						bottom: -8px;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}

			.mainMenu, 
			.videoInfo,
			.lc-logo {
				opacity: 1;
			}

		}
		
		&.playing {
			z-index: 2;
			box-shadow: 0px 0px 0px 500px rgba(0, 0, 0, 0.75);
			flex-grow: 999;

			.text,
			.mainMenu,
			.videoInfo,
			.lc-logo, 
			&::after {
				display: none;
			}
		}

		.text {
			transition: all 500ms ease;
			pointer-events: none;
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(255,255,255,0.3);
			color: #ffffff;
			line-height: 1.4rem;
			z-index: 1;
			top: 0;

			p {
				display: inline-block;
				transition: all 500ms ease;
				position:absolute;
				text-align: left;
				width: 80vh;
				max-width: 100vw;
				top: 50%;
				left: calc(10% + 90px);
				transform: translate(-50%, -50%) rotate(-90deg);

				span {
					display: block;
					position: relative;

					/*&:first-child {
						font-size: 1.2rem;
						letter-spacing: 1.2rem;

						&::after {
							content: '';
							display: inline-block;
							position: absolute;
							padding-left: 0.8rem;
							left: 0;
							bottom: -8px;
							width: 115px;
							height: 2px;
							background: #ffffff;
							margin: auto;
						}
					}*/

					&:nth-child(1) {
						font-family: Marcellus;
						margin-top: 10px;
					}
				}
			}
		}

		.mainMenu {
			opacity: 0;
		}

		.videoInfo {
			position: absolute;
			bottom: 10%;
			left: 10%;
			z-index: 1;
			transition: opacity 500ms ease;
			opacity: 0;

			p {
				font-size: 1.2rem;

				span.ep {
					font-size: 1.6rem;
				}

				span.runtime {
					color: $tertiaryColor;
				}
			}
		}
		
		img:not([src$="svg"]),
		video,
		 {
			position: absolute;
			// width: initial;
			width: calc(100vh / 1080 * 1920);
			height: 100%;
			object-fit: cover;
			transition: box-shadow 500ms ease;
			line-height: 0;
		}

		.lc-logo {
			transition: opacity 500ms ease;
			opacity: 0;
			position: absolute;
			width: 20vw;
			height: auto;
			top: 45%;
			left: 50%;
			transform: translate(-50%,-50%);
			pointer-events: none;
			min-width: 150px;

			g:first-of-type {
				transform-box: fill-box;
				transform-origin: 50% 50%;
				transform: rotate(0deg);
			}

			@media screen and (max-height: 599px) {
				display: none;
			}
			@media screen and (min-height: 650px) {
				min-width: 200px;
			}
		}

	}
	
	@media screen and (max-aspect-ratio: 16/9) { 
		flex-direction: column;
		height: 100%;
		max-width: none;

		&:not(.playing) {

			.slideContainer {
				min-height: 100px;
				height: 100px;

				video, 
				img:not([src$="svg"]) {
					min-width: 100%;
					height: calc(100vh - 200px);
				}
			}
		}
		
		.slideContainer {
			// max-height: calc(100vw / 1920 * 1080);

			&:not(:hover) {
				.text p {
					transform: translate(0, -50%);
				}
			}

			&:hover {
				.text p {
					// transform: translate(-50%, -50%);
					// left: 50%;
				}
			}

			&.playing {

				video {
					top: 50%;
					transform: translateY(-50%);
				}
			}
			
			video, 
			img:not([src$="svg"]) {
				width: 100%;
				height: auto;
			}

			&::after {
				box-shadow: inset 0px 7px 10px -6px rgba(0,0,0,0.75);
			}
			
		}
	}
}