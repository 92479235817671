/**
 * BASED ON:
 *
 * Carousel.sass
 * @author: Dang Van Thanh
 * @github: https://github.com/dangvanthanh/carousel.sass
 * @description: A Simple Carousel Pure CSS Using Sass
 * @version: 1.0.0
 */

$animationDuration: 1s;

%animation-default {
  display: inline-block !important;
  opacity: 1 !important;
  z-index: 3;
}

%next_prev {

  > .overlay {
    
    @media screen and (max-width: $breakpointL1 - 1px) {
      background: transparent !important;
    }

    .playbutton {
      display: none;
    }

    label {
      display: inline-block !important;
    }
  }
  
  @media screen and (min-width: $breakpointL1) {
    display: inline-block !important;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    animation-name: nextVideoLast;
    transform-origin: 0% 0%;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
  }

}

%next_main {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  z-index: 1;
  
  @media screen and (min-width: $breakpointL1) {
    display: inline-block !important;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    transform: translateX(102.5%) scale(0.465);
    animation-name: nextVideoMain;
    transform-origin: 0% 0%;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
  }

  * {
    cursor: auto !important;
  }

  video {
    pointer-events: auto !important;

    // ~ .overlay .videoInfo p {
    //   display: none;
    // }
  }

  > img {

    ~ .overlay .videoInfo .playbutton {
      display: none;
    }
  }

  > .overlay {
    pointer-events: none;

    .videoInfo {
      display: flex !important;
      width: auto !important;

      p {
        font-size: 1.2rem;

        span.ep {
          font-size: 1.6rem;
        }
      }
    }
  }
}

%next_second {
  
  @media screen and (min-width: $breakpointL1) {
    display: inline-block !important;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    transform: translateY(115%);
    animation-name: nextVideoSecond;
    transform-origin: 0% 0%;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
  }

  > .overlay {

    @media screen and (max-width: $breakpointL1 - 1px) {
      background: transparent !important;
    }

    .playbutton {
      display: none;
    }

    label:nth-of-type(2) {
      display: inline-block !important;
    }
  }
}

%next_third {

  @media screen and (min-width: $breakpointL1) {
    display: inline-block !important;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    transform: translateX(150%);
    opacity: 0%;
    animation-name: nextVideoThird;
    transform-origin: 0% 0%;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
  }

  video {
    position: absolute;
    bottom: 0;
  }

  > .overlay {

    @media screen and (max-width: $breakpointL1 - 1px) {
      background: transparent !important;
    }

    .playbutton {
      display: none;
    }

    label:nth-of-type(1) {
      display: inline-block !important;
    }
  }
}

%prev_main {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  z-index: 1;

  @media screen and (min-width: $breakpointL1) {
    display: inline-block !important;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    animation-name: previousVideoMain;
    transform-origin: 0% 0%;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
  }
  
  * {
    cursor: auto !important;
  }

  video {
    pointer-events: auto !important;

    // ~ .overlay .videoInfo p {
    //   display: none;
    // }
  }

  > img {

    ~ .overlay .videoInfo .playbutton {
      display: none;
    }
  }

  > .overlay {
    pointer-events: none;

    .videoInfo {
      display: flex !important;
      width: auto !important;

      p {
        font-size: 1.2rem;

        span.ep {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@keyframes previousVideoMain {
  0% {
    transform: translateX(-200%);
    opacity: 0;
  }
  

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

%prev_second {
  z-index: 0;
  
  @media screen and (min-width: $breakpointL1) {
    display: inline-block !important;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    animation-name: previousVideoSecond;
    transform-origin: 0% 0%;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
  }

  > .overlay {

    @media screen and (max-width: $breakpointL1 - 1px) {
      background: transparent !important;
    }

    .playbutton {
      display: none;
    }

    label:nth-of-type(2) {
      display: inline-block !important;
    }
  }
}

@keyframes previousVideoSecond {
  0% {
    transform: translateX(-221%) scale(2.15);
  }
  
  100% {
    transform: translateX(0%) scale(1);
  }
}

%prev_third {
  
  @media screen and (min-width: $breakpointL1) {
    display: inline-block !important;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    animation-name: previousVideoThird;
    transform-origin: 0% 0%;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
  }

  video {
    position: absolute;
    bottom: 0;
  }

  > .overlay {

    @media screen and (max-width: $breakpointL1 - 1px) {
      background: transparent !important;
    }

    .playbutton {
      display: none;
    }

    label:nth-of-type(1) {
      display: inline-block !important;
    }
  }
}

@keyframes previousVideoThird {
  0% {
    transform: translateY(-115%);
  }
  
  100% {
    transform: translateY(0%);
  }
}

%prev_fourth {
  
  > .overlay {

    @media screen and (max-width: $breakpointL1 - 1px) {
      background: transparent !important;
    }

    .playbutton {
      display: none;
    }

    label {
      display: inline-block !important;
    }
  }

  @media screen and (min-width: $breakpointL1) {
    display: inline-block !important;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    animation-name: previousVideoFourth;
    transform-origin: 0% 0%;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
  }
}

@keyframes previousVideoFourth {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  
  99% {
    transform: translateX(150%);
    opacity: 0;
  }

  100% {
    transform: translateX(150%);
    opacity: 0;
    display: none;
  }
}

@keyframes nextVideoLast {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  
  99% {
    transform: translateX(-200%);
    opacity: 0;
  }

  100% {
    transform: translateX(-200%);
    opacity: 0;
    display: none;
  }
}

@keyframes nextVideoMain {
  0% {
    transform: translateX(102.5%) scale(0.465);
  }
  
  100% {
    transform: translateX(0%) scale(1);
  }
}

@keyframes nextVideoSecond {
  0% {
    transform: translateY(115%);
  }
  
  100% {
    transform: translateY(0%);
  }
}

@keyframes nextVideoThird {
  0% {
    transform: translateX(150%);
    opacity: 0;
  }
  
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@mixin carousel($items) {
  .videoGrid {
    position: relative;

    > input[type="radio"] {
      display: none;

      ~ .subline .text > span,
      ~ .subline .text > img,
      ~ .subline nav .prev > label,
      ~ .subline nav .next > label {
        display: none;
      }

      ~ .videoElement {
        display: inline-block;

        @media screen and (min-width: $breakpointL1) {
          display: none;
        }
      }

      @for $i from 1 through $items * 2 {
        &:nth-of-type(#{$i}) {
          &:checked {
            ~ .videoElement {

              @if $i % 2 == 0 {
                @if $i <= 2 {
                  &:nth-of-type(#{$items}) {
                    @extend %next_prev;
                  }
                } @else {
                  &:nth-of-type(#{($i / 2) - 1}) {
                    @extend %next_prev;
                  }
                }

                &:nth-of-type(#{$i/2}) {
                  @extend %next_main;
                }

                &:nth-of-type(#{(($i / 2) % $items) + 1}) {
                  @extend %next_second;
                }

                &:nth-of-type(#{(((($i / 2) % $items) + 1) % $items ) + 1}) {
                  @extend %next_third;
                }
              }

              @else if $i % 2 == 1 {
                &:nth-of-type(#{($i + 1) / 2}) {
                  @extend %prev_main;
                }

                &:nth-of-type(#{((($i + 1) / 2) % $items) + 1}) {
                  @extend %prev_second;
                }

                &:nth-of-type(#{((((($i + 1) / 2) % $items) + 1) % $items ) + 1}) {
                  @extend %prev_third;
                }

                &:nth-of-type(#{((((((($i + 1) / 2) % $items) + 1) % $items ) + 1) % $items) + 1}) {
                  @extend %prev_fourth;
                }
              }
            }

            ~ .subline nav .prev {
              > label {
                @if $i % 2 == 0 {
                  @if $i <= 2 {
                    &:nth-of-type(#{$items}) {
                      @extend %animation-default;
                    }
                  } @else {
                    &:nth-of-type(#{($i / 2) - 1}) {
                      @extend %animation-default;
                    }
                  }
                } @else {
                  @if $i <= 2 {
                    &:nth-of-type(#{$items}) {
                      @extend %animation-default;
                    }
                  } @else {
                    &:nth-of-type(#{(($i + 1) / 2) - 1}) {
                      @extend %animation-default;
                    }
                  }
                }
              }
            }

            ~ .subline nav .next {
              > label {

                @if $i % 2 == 0 {
                  &:nth-of-type(#{(($i / 2) % $items) + 1}) {
                    @extend %animation-default;
                  }
                } @else if $i % 2 == 1 {
                  &:nth-of-type(#{((($i + 1) / 2) % $items) + 1}) {
                    @extend %animation-default;
                  }
                }
              }
            }

            ~ .subline .text {
              > span,
              > img {
                @if $i % 2 == 0 {
                  &:nth-of-type(#{$i / 2}) {
                    @extend %animation-default;
                  }
                } @else if $i % 2 == 1 {
                  &:nth-of-type(#{($i + 1) / 2}) {
                    @extend %animation-default;
                  }
                }
              }
            }

          }
        }
      }
    }
  }
}

@include carousel(4);