#section0 {

	.logo {
		text-align: center;

		img {
			width: 20%;
			min-width: 280px;
		}
	}

	.arrow {
		position: absolute;
		bottom: 8%;
		left: calc(50% + 6px);
		fill: $primaryColor;
		transform: translateX(-50%);
		width: 50px;
	}
}

#section1 {

	.slide-content {
		height: 100vh;
		padding-top: 0;
		padding-bottom: 0;

		#slideContainer1 {
			.lc-logo {
				top: 18%;
			}
		}
		#slideContainer2 {
			.lc-logo {
				left: 24%;
			}
		}
	}
}

#section2 {

	.header {
		margin-top: 5rem;
	}
}

#section3 {

	background-position: 25% 0%;
	background-size: cover;
	background-image: url('../img/sec3_1920x1080.jpg');
	
	@mixin backgroundImage($minWidth, $maxWidth, $dimensions) {
		@media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
			background-image: url('../img/sec3_#{$dimensions}.jpg');
		}
	}
	
	@each $minWidth, $maxWidth, $dimensions in $imageSizesList {
		@include backgroundImage($minWidth, $maxWidth, $dimensions);
	}

	.header {
		margin-top: 5rem;
	}
	
	.slide-content {
		color: $textColor_1;
		align-items: flex-end;
		
		.content,
		.header {
			
			@media screen and (max-width: $breakpointS2 - 1px) {
				text-align: left;
			}
			@media screen and (min-width: $breakpointL1) {
				width: 650px;
			}
		}
	}
}

@keyframes videoMove_1 {
	0% {
		transform: translateX(0%);
		opacity: 100%;
	}
	
	100% {
		transform: translateX(-150%);
		opacity: 0%;
	}
}

@keyframes videoMove_2 {
	0% {
		transform: translateX(0%) scale(1)
	}
	
	100% {
		transform: translateX(-220%) scale(2.1)
	}
}

@keyframes videoMove_3 {
	0% {
		transform: translateY(0%);
	}
	
	100% {
		transform: translateY(-115%);
	}
}

@keyframes videoMove_4 {
	0% {
		transform: translateX(150%);
		opacity: 0%;
	}
	
	100% {
		transform: translateX(0%);
		opacity: 100%;
	}
}

#section4 {
	
	.header {
		
		.flex {
			justify-content: space-around;
			align-items: baseline;
			
			> * {
				flex: 1;
			}
			
			h2 {
				position: relative;
				padding-left: $letterSpacingH2;
				font-weight: $normal;
				
				&::after {
					content: '';
					position: absolute;
					width: calc(100% - (2 * #{$letterSpacingH2}));
					bottom: -5px;
					left: $letterSpacingH2;
					height:1px;
					background-color: $textColor_0;
				}
			}
		}
		
		.subheading {
			padding-left: $letterSpacingSubheading;
		}
		
	}
	
	.content {
		margin-bottom: 4em;
		
		.oneColumn {
			
			.videoGrid {
				display: grid;
				line-height: 0;
				grid-gap: $videoGridGapS;
				grid-template-columns: 1fr 1fr 1fr;
				position: relative;

				@media screen and (min-width: $breakpointL1) {
					grid-gap: $videoGridGapL;
					grid-template-columns: 63fr 29fr;
					grid-template-rows: 1fr 1fr;
				}
				@media screen and (min-width: $breakpointL2) {
					grid-template-columns: 62fr 29fr;
				}
				@media screen and (min-width: $breakpointL3) {
					grid-template-columns: 63fr 30fr;
				}
				
				input[name="videoSwitch"] {
					display: none;
				}
		
				.videoElement {
					position: relative;
					
					@media screen and (min-width: $breakpointL1) {
						display: none;
					}

					&:hover,
					*:hover {
						cursor: pointer;
					}

					&:not(.playing):hover {
						
						.overlay {
							display: flex;
						}
					}

					&.playing {

						.overlay {
							display: none;
						}
					}

					.overlay {
						position: absolute;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						background-color: rgba(150,99,62,0.5);
						
						@media screen and (min-width: $breakpointL1) {
							display: none;
						}

						label {
							display: none;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
						}

						.videoInfo {
							display: none;
							width: 80%;

							@media screen and (min-width: $breakpointL1) {
								display: flex;
							}
						}

					}

					video {
						pointer-events: none;
					}

				}

				&.playing {
					.subline {
						img.playbutton {
							display: none !important;
						}
					}
				}
					
				
				.subline {
					display: flex;
					justify-content: space-between;
					line-height: 1.4;
					grid-column-start: 1;
    				grid-column-end: 4;
    				grid-row-start: 2;
    				grid-row-end: 3;

    				@media screen and (min-width: $breakpointL1) {
						grid-column-start: 1;
	    				grid-column-end: 2;
	    				grid-row-start: 3;
	    				grid-row-end: 4;
    				}

    				.text {
    					display: flex;
    					align-items: center;

    					@media screen and (min-width: $breakpointL1) {
    						display: inline-block;
    					}

    				}
					
					> * {
						display: inline-block;
						position: relative;
					}

					img.playbutton {
						height: 45px;
						pointer-events: all;
						vertical-align: bottom;
						margin-right: 10px;
					
						&:hover {
							cursor: pointer;
						}
					}
					
					nav {
						display: none;

						@media screen and (min-width: $breakpointL1) {
							display: inline-block;
							width: 5em;
						}

						@media screen and (min-width: $breakpointL2) {
							width: 6em;
						}
						
						svg {
							position: absolute;
							box-sizing: border-box;
							cursor: pointer;
							fill: $primaryColor;
							height: 100%;
							padding: 7px 0 3px;
							
							&.left {
								left: 0;
							}
							
							&.right {
								right: 0;
							}
						}
						
						label {
							height: 100%;
							width: 100%;
							cursor: pointer;
						}

						.prev { 
							position: absolute;
							height: 100%;
							width: 50%;
							left: 0;
						}

						.next {
							position: absolute;
							height: 100%;
							width: 50%;
							right: 0;
						}
					}
				}
				
			}
		}
	}
	
}

@keyframes fadeOutBorder {
  0% {
	border-color: #{$textColor_0}FF;
	max-height: 52px;
  }

  50% {
	border-color: #{$textColor_0}FF;
	max-height: 1000px;
  }
  
  100% {
	border-color: #{$textColor_0}#{0}#{0};
	max-height: 1000px;
  }
  
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#appointmentSwitcher {

	&:not(:checked) ~ {

		#section5 .slide-content {
			display: none;
		}
	}

	&:checked ~ {
		
		#section5 .slide-content {
			display: flex !important;
		}
		
		#footer {
			
			.oneColumn > .flex {
				transition: transform $animationStepDuration ease 0s, max-height $animationStepDuration ease 0s, opacity calc(#{$animationStepDuration} / 2) ease 0s;
				transform: translateY(-100%);
				max-height: 0;
				opacity: 0;
				overflow: hidden;
			}
			
			.appointmentContainer {
				transition: all $animationStepDuration ease;
				/*margin-top: 0;*/
				
				.appointment {
					animation-name: fadeOutBorder;
					animation-delay: $animationStepDuration;
					animation-duration: calc(#{$animationStepDuration * 2});
					animation-fill-mode: forwards;
					animation-timing-function: ease;
					
					> label {
						transform: translateX(-100%);
						color: $bgColor_0;
						background: $textColor_0;
					}
					
					form {
						position: relative;
						animation-name: fadeIn;
						animation-delay: calc(#{$animationStepDuration * 2});
						animation-duration: $animationStepDuration;
						animation-fill-mode: forwards;
						animation-timing-function: ease;
					}
				}
			}
		}
	}
}

.videoInfo {
	// display: flex;
	align-items: center;
	color: #ffffff;

	img.playbutton {
		transition: all 500ms ease;
		height: 60px;
		pointer-events: all;
		vertical-align: bottom;
		margin-right: 10px;
	
		&:hover {
			cursor: pointer;
			transform: scale(1.2);
		}
	}

	p {
		margin-top: 0;
		margin-left: 20px;
		font-size: 0.9rem;
		line-height: 1.8;
		width: 100%;
		
		@media screen and (min-width: $breakpointL2) {
			font-size: 1.2rem;
		}

		span {
			display: block;

			&.ep {
				position: relative;
				font-size: 1rem;
				letter-spacing: 1rem;
				
				@media screen and (min-width: $breakpointL2) {
					font-size: 1.6rem;
				}

				&::after {
					content: '';
					position: absolute;
					display: inline-block;
					width: 130px;
					height: 2px;
					bottom: -2px;
					left: 0;
					background: $tertiaryColor;
				}
			}

			&.title {
				font-family: Marcellus;
				line-height: 1.4;
				margin-top: 10px;
			}
		}
	}
}


#footer,
#section5 {

	&.active {
		
		.flex {
			
			ul {
				
				li {
					
					&:nth-child(3) {
						animation-name: growFontSize;
						animation-duration: 1000ms;
						animation-delay: 500ms;
						animation-direction: normal;
						animation-fill-mode: forwards;
						
						&::before {
							border-bottom-color: $textColor_0;
							animation-name: expandWidth;
							animation-duration: 1000ms;
							animation-delay: 500ms;
							animation-direction: normal;
							animation-fill-mode: forwards;
						}
					}
				}
			}
		}
	}
	
	&:not(.active) {
		
		.flex {
			
			ul {
				
				li {
					
					&:last-child {
						font-size: 25px;
						
						&::before {
							width: 100%;
							border-bottom-color: $textColor_0;
						}
					}
				}
			}
		}
	}
	
	.content {
		margin-top: 4rem;

		.appointmentContainer {
			display: flex;
			justify-content: center;
			margin-top: 2rem;
			
			.appointment {
				position: relative;
				display: inline-block;
				z-index: 0;
				overflow: hidden;
				width: 100%;
				border: 1px solid $textColor_0;
				max-height: 70px;
				height: auto;
				
				@media screen and (min-width: $breakpointL1) {
					width: calc((100% / 12) * 7);
				}
				@media screen and (min-width: $breakpointL3) {
					max-height: 52px;
				}
				
				> label,
				> #successMessage {
					display: inline-block;
					z-index: 1;
					width: 100%;
					height: 100%;
					text-transform: uppercase;
					letter-spacing: 1rem;
					font-size: 16px;
					padding: 10px;
					box-sizing: border-box;
					text-align: center;					
					
					@media screen and (min-width: $breakpointS2) {
						letter-spacing: 1.2rem;
					}
					
					&::before {
						content: '\0000a0';
						display: inline-block;
						width: 0;
					}
				}

				> label {
					position: absolute;
					color: $textColor_0;
					background: $bgColor_0;
					cursor: pointer;
					transition: all $animationStepDuration ease;
					transform: translateX(0%);

					&:hover {
						color: $bgColor_0;
						background: $textColor_0;
					}
				}

				> #successMessage {
					height: 100px;
					color: $bgColor_0;
					background: $textColor_0;
				}
				
				form {
					z-index: -1;
					opacity: 0;
					padding: 10px;
					
					* {
						display: block;
						line-height: 1.2;
						letter-spacing: 0.1rem;
						width: 100%;
					}
					
					input {
						border: none;
						border-bottom: 1px solid $textColor_0;
						padding: 5px 0;
						background: transparent;
						
						&[type="submit"] {
							border: 1px solid $textColor_0;
							transition: all 500ms ease;
							margin-top: 1rem;
							
							&:hover {
								color: $bgColor_0;
								background: $textColor_0;
							}
						}

						&[type="date"] {
							opacity: 0.75;
						}
					}
					
					label {
						
						&:not(:first-child) {
							margin-top: 2rem;
						}
						
						&:last-of-type {
							
							@media screen and (min-width: $breakpointL1) {
								font-size: 25px;
							}
							
							textarea {
								border: none;
								border-top: 1px solid $textColor_0;
								padding: 10px 0;
								box-sizing: border-box;
								max-width: 100%;
							}
						}

						@media screen and (min-width: $breakpointS2) {
							letter-spacing: $letterSpacingSubheading;
						}
					}

					::-webkit-input-placeholder { /* WebKit, Blink, Edge */
					    opacity: 0.75;
					}
					:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
					    opacity: 0.75;
					}
					::-moz-placeholder { /* Mozilla Firefox 19+ */
					   opacity:  0.75;
					}
					:-ms-input-placeholder { /* Internet Explorer 10-11 */
						opacity: 0.75;
					}
					::-ms-input-placeholder { /* Microsoft Edge */
						opacity: 0.75;
					}

					::placeholder { /* Most modern browsers support this now. */
						opacity: 0.75;
					}
				}
			}
		}
		
		.logo {
			display: block;
			text-align: center;
			margin-top: 5rem;
		
			img[src$="svg"] {
				width: 20%;
				min-width: 200px;
			}
		}
	}
}

#section5,
#footer {

	.oneColumn {

		@media screen and (max-width: $breakpointL1 - 1px) {
			display: flex;
			flex-direction: column;

			> .flex {
				order: 2;
			}

			> .logo {
				order: 3;
			}
		}
	
		> .flex {
			flex-direction: column;
			align-items: center;
			height: auto;

			// Mindesthöhe
			$minHeight: 450px;

			@media screen and (min-width: $breakpointL1) {
				flex-direction: row;
				align-items: flex-start;
				max-height: 800px;
			}

			> div {
				height: auto;
				min-height: $minHeight;
				flex: 1;
				
				@media screen and (min-width: $breakpointL1) {
					height: 50vh;
				}

				p {
					&:not(:first-child) {
						margin-top: 1rem;
					}
				}

				&.map {

					@media screen and (max-width: $breakpointL1 - 1px) {
						order: 2;
						margin-top: 4rem;
					}
				}

				&.text {
					margin-top: 4rem;
					text-align: center;
					width: 100%;
					
					@media screen and (min-width: $breakpointL1) {
						margin-top: 0;
						text-align: left;
						width: auto;
						margin-left: 10%;
					}
				}
			}

			iframe {
				border: 1px solid #96633e;
				width: 60vh;
				min-width: calc(#{$minHeight} * 0.6);
				max-width: 100%;
				height: calc(50vh - 2rem - 7px);
				min-height: calc(#{$minHeight} - 2rem - 7px);
				margin-top: 7px;
			}
			
			/*ul {
				list-style: none;
				letter-spacing: $letterSpacingSubheading;
				text-transform: uppercase;
				margin-bottom: 5px;
				
				li {
					font-size: 14px;
					
					&:not(:first-child) {
						margin-top: 1rem;
					}
					
					&:last-child {
						border: 0;
						padding-bottom: 3px;
						position: relative;
						vertical-align: middle;
						
						&::before {
							box-sizing: inherit;
							content: '';
							position: absolute;
							left: 0;
							bottom: 0;
							height: 0;
							border-bottom: 1px solid transparent;
						}
					}
				}
			}*/
		}
	}
}

#footer {

	.slide-content {
		padding-bottom: 0;

		.content {
			min-height: 950px;
		}
	}
}

.datenschutz,
.impressum {
	margin-top: 5rem;
	margin-bottom: 5rem;

	h1 {
		font-family: Muli, 'Helvetica Neue', Arial, sans-serif;
	}

	h2 {
		font-size: 1.2rem;
		font-weight: 400;
		letter-spacing: initial;
	}
}

@keyframes expandWidth {
	0% {
		width: 0;
	}
	
	100% {
		width: 100%;
	}
}

@keyframes growFontSize {
	0% {
		font-size: 14px;
	}
	
	100% {
		font-size: 25px;
	}
}