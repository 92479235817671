//***** VARIABLES *****
$letterSpacingH2: 1.6rem;
$letterSpacingSubheading: 1.2rem;
$videoGridGapS: 10px;
$videoGridGapL: 20px;
$animationStepDuration: 750ms;

//***** TYPEFACES *****
$light: 300;
$normal: 300;
$semibold: 500;
$bold: 600;

//***** COLORS *****
$primaryColor: #96633e;
$secondaryColor: #626262;
$tertiaryColor: #D89964;


// Standard
$textColor_0: #96633e;
$textColor_1: #ffffff;
$linkColor_0: #96633e;
$linkHoverColor_0: #96633e;
$bgColor_0: #ffffff;

// Breakpoints
$breakpointS1: 320px;
$contentWidthS1: 300px;
$contentMarginTopS1: 1rem;
$contentPaddingS1: 10px;

$breakpointS2: 520px;
$contentWidthS2: 480px;
$contentMarginTopS2: 1rem;
$contentPaddingS2: 20px;

$breakpointL1: 780px;
$contentWidthL1: 720px;
$contentMarginTopL1: 1rem;
$contentPaddingL1: 30px;

$breakpointL2: 1040px;
$contentWidthL2: 960px;
$contentMarginTopL2: 2rem;
$contentPaddingL2: 40px;

$breakpointL3: 1300px;
$contentWidthL3: 1200px;
$contentMarginTopL3: 3rem;
$contentPaddingL3: 50px;

$breakpointL4: 1560px;
$contentWidthL4: 1440px;
$contentMarginTopL4: 4rem;
$contentPaddingL4: 60px;

$breakpointL5: 1820px;
$contentWidthL5: 1680px;
$contentMarginTopL5: 5rem;
$contentPaddingL5: 70px;

$breakpointVerticalToHorizontal: $breakpointL2;

$breakpointList: (0: $contentWidthS1, $breakpointS2: $contentWidthS2, $breakpointL1: $contentWidthL1, $breakpointL2: $contentWidthL2, $breakpointL3: $contentWidthL3, $breakpointL4: $contentWidthL3, $breakpointL5: $contentWidthL3);

$imageSizesList: ('0px' '650px' '650x366', '651px' '780px' '780x439', '781px' '910px' '910x512', '911px' '1280px' '1280x720', '1281px' '1920px' '1920x1080');

// Header
$headerHeightS1: 80px;
$headerHeightS2: 100px;
$headerHeightL1: 100px;
$headerHeightL2: 166px;
$headerHeightL3: 180px;
$headerHeightL4: 180px;
$headerHeightL5: 190px;
$headerHeightList: ($headerHeightS1, $headerHeightS2, $headerHeightL1, $headerHeightL2, $headerHeightL3, $headerHeightL4, $headerHeightL5);
