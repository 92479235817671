/* muli-300 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../../external/fonts/muli-v17-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../external/fonts/muli-v17-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* muli-600 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../../external/fonts/muli-v17-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../external/fonts/muli-v17-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* marcellus-regular - latin */
@font-face {
  font-family: 'Marcellus';
  font-style: normal;
  font-weight: 400;
  src: local('Marcellus'), local('Marcellus-Regular'),
       url('../../external/fonts/marcellus-v7-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../external/fonts/marcellus-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
