.wrapper {
	max-width: 1920px;
	margin: 0 auto;
}

.slide-content {
	width: 100vw;
	min-height: 100vh;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	padding-top: 65px;
	padding-bottom: 65px;
}

main {

	.mainHeader {
		position: relative;

		.image {

			img {
				width: 100%;
				height: auto;
			}
		}

		.text {
			position: absolute;
			bottom: 10%;
			color: #ffffff;

			p {
				margin-top: 0;
				font-size: 1.25rem;
			}
		}
	}

	.mainHeader .text,
	.mainHeader .frame,
	.header,
	.contentMargins	{
		margin-left: 10px;
		margin-right:10px;
		
		@mixin margin($breakpoint, $contentWidth) {
			@media screen and (min-width: $breakpoint) {
				margin-left: calc((100% - #{$contentWidth}) / 2);
				margin-right: calc((100% - #{$contentWidth}) / 2);
			}
			
			@media screen and (max-width: $breakpointVerticalToHorizontal - 1px) {
				margin-top: 2rem;
			}
		}
		
		@each $breakpoint, $contentWidth in $breakpointList {
			@include margin($breakpoint, $contentWidth);
		}
		
	}

	.article .content {
			
		@mixin marginTop($breakpoint, $contentMarginTop) {
			@media screen and (min-width: $breakpoint) {
				margin-top: $contentMarginTop;
			}
		}

		@include marginTop(0, 2em);
		@include marginTop($breakpointS2, 2em);
		@include marginTop($breakpointL1, 2em);
		@include marginTop($breakpointL2, 3em);
		@include marginTop($breakpointL3, 3em);
		@include marginTop($breakpointL4, 4em);
		@include marginTop($breakpointL5, 4em);
	}

	.section .content {
			
		@mixin marginTop($breakpoint, $contentMarginTop) {
			@media screen and (min-width: $breakpoint) {
				margin-top: $contentMarginTop;
			}
		}

		@include marginTop(0, 2em);
		@include marginTop($breakpointS2, 2em);
		@include marginTop($breakpointL1, 2em);
		@include marginTop($breakpointL2, 2em);
		@include marginTop($breakpointL3, 4em);
		@include marginTop($breakpointL4, 4em);
		@include marginTop($breakpointL5, 4em);
		
	}
		
	.section .content.marginLeft,
	.contentMarginLeft	{
		@mixin leftMargin($breakpoint, $contentPadding) {
			@media screen and (min-width: $breakpoint) {
				margin-left: $contentPadding;
			}
		}
		
		@include leftMargin(0, $contentPaddingS1);
		@include leftMargin($breakpointS2, $contentPaddingS2);
		@include leftMargin($breakpointL1, $contentPaddingL1);
		@include leftMargin($breakpointL2, $contentPaddingL2);
		@include leftMargin($breakpointL3, $contentPaddingL3);
		@include leftMargin($breakpointL4, $contentPaddingL3);
		@include leftMargin($breakpointL5, $contentPaddingL3);
	}
	
	.section .content.marginLeftS,
	.contentMarginLeftS	{
		@mixin leftMargin($breakpoint, $contentPadding) {
			@media screen and (min-width: $breakpoint) and (max-width: $breakpointL1 - 1px) {
				margin-left: $contentPadding;
			}
		}
		
		@include leftMargin(0, $contentPaddingS1);
		@include leftMargin($breakpointS2, $contentPaddingS2);
	}
	
	.section .content.marginRight,
	.contentMarginRight	{
		@mixin rightMargin($breakpoint, $contentPadding) {
			@media screen and (min-width: $breakpoint) {
				margin-right: $contentPadding;
			}
		}
		
		@include rightMargin(0, $contentPaddingS1);
		@include rightMargin($breakpointS2, $contentPaddingS2);
		@include rightMargin($breakpointL1, $contentPaddingL1);
		@include rightMargin($breakpointL2, $contentPaddingL2);
		@include rightMargin($breakpointL3, $contentPaddingL3);
		@include rightMargin($breakpointL4, $contentPaddingL3);
		@include rightMargin($breakpointL5, $contentPaddingL3);
	}
	
	.contentPadding	{
		@mixin contentPadding($breakpoint, $contentPadding) {
			@media screen and (min-width: $breakpoint) {
				padding-right: $contentPadding;
				padding-left: $contentPadding;
			}
		}
		
		@include contentPadding(0, $contentPaddingS1);
		@include contentPadding($breakpointS2, $contentPaddingS2);
		@include contentPadding($breakpointL1, $contentPaddingL1);
		@include contentPadding($breakpointL2, $contentPaddingL2);
		@include contentPadding($breakpointL3, $contentPaddingL3);
		@include contentPadding($breakpointL4, $contentPaddingL3);
		@include contentPadding($breakpointL5, $contentPaddingL3);
	}
	
	.contentPaddingRight	{
		@mixin rightPadding($breakpoint, $contentPadding) {
			@media screen and (min-width: $breakpoint) {
				padding-right: $contentPadding;
			}
		}
		
		@include rightPadding(0, $contentPaddingS1);
		@include rightPadding($breakpointS2, $contentPaddingS2);
		@include rightPadding($breakpointL1, $contentPaddingL1);
		@include rightPadding($breakpointL2, $contentPaddingL2);
		@include rightPadding($breakpointL3, $contentPaddingL3);
		@include rightPadding($breakpointL4, $contentPaddingL3);
		@include rightPadding($breakpointL5, $contentPaddingL3);
	}
	
	.contentPaddingLeft	{
		@mixin leftPadding($breakpoint, $contentPadding) {
			@media screen and (min-width: $breakpoint) {
				padding-left: $contentPadding;
			}
		}
		
		@include leftPadding(0, $contentPaddingS1);
		@include leftPadding($breakpointS2, $contentPaddingS2);
		@include leftPadding($breakpointL1, $contentPaddingL1);
		@include leftPadding($breakpointL2, $contentPaddingL2);
		@include leftPadding($breakpointL3, $contentPaddingL3);
		@include leftPadding($breakpointL4, $contentPaddingL3);
		@include leftPadding($breakpointL5, $contentPaddingL3);
	}
	

	.content {
		display: flex;
		flex-direction: column;

		@media (min-width: $breakpointVerticalToHorizontal) {
			flex-direction: row;
		}

		.leftColumn,
		.rightColumn {

			@media (min-width: $breakpointVerticalToHorizontal) {
				width: 50%;
			}
		}

		.mBorderImage {
			margin-top: 1rem;
		}

		.leftColumn {

			.text {
				margin-left: 10px;
				margin-right: 10px;
				
				@mixin widthAndMargins($breakpoint, $contentWidth) {
					@media screen and (min-width: $breakpoint) and (max-width: $breakpointVerticalToHorizontal - 1px) {
						width: #{$contentWidth};
						margin-left: calc((100% - #{$contentWidth}) / 2);
						margin-right: calc((100% - #{$contentWidth}) / 2);
					}
					
					@media screen and (min-width: $breakpoint) and (min-width: $breakpointVerticalToHorizontal) {
						width: calc(100% - (100% - (#{$contentWidth} / 2)));
						margin-left: calc(100% - (#{$contentWidth} / 2));
						padding-right: calc(#{$contentWidth} / 24);
					}
				}
				
				@each $breakpoint, $contentWidth in $breakpointList {
					@include widthAndMargins($breakpoint, $contentWidth);
				}
				
			}

			.mBorderImage {
				margin-top: 10vh;
				
				@mixin imageMargin($breakpoint, $contentPadding) {
					@media screen and (min-width: $breakpoint) {
						width: calc(100% - #{$contentPadding});
						margin-right: $contentPadding;
					}
				}
				
				@include imageMargin($breakpointL1, $contentPaddingL1);
				@include imageMargin($breakpointL2, $contentPaddingL2);
				@include imageMargin($breakpointL3, $contentPaddingL3);
				@include imageMargin($breakpointL4, $contentPaddingL3);
				@include imageMargin($breakpointL5, $contentPaddingL3);
			}
		}

		.rightColumn {

			@media (max-width: $breakpointVerticalToHorizontal - 1px) {
				&:not(.order2) {
					margin-top: 1.6rem;
				}
			}

			.text {
				margin-left: 10px;
				margin-right: 10px;
				
				@mixin widthAndMargins($breakpoint, $contentWidth) {
					@media screen and (min-width: $breakpoint) and (max-width: $breakpointVerticalToHorizontal - 1px) {
						width: #{$contentWidth};
						margin-left: calc((100% - #{$contentWidth}) / 2);
						margin-right: calc((100% - #{$contentWidth}) / 2);
					}
					
					@media screen and (min-width: $breakpoint) and (min-width: $breakpointVerticalToHorizontal) {
						width: calc(100% - (100% - (#{$contentWidth} / 2)));
						margin-right: calc(100% - (#{$contentWidth} / 2));
						padding-right: calc(#{$contentWidth} / 24);
					}
				}
				
				@each $breakpoint, $contentWidth in $breakpointList {
					@include widthAndMargins($breakpoint, $contentWidth);
				}
				
			}
			
			&> .image {
				
			}

			.mBorderImage {
				
				margin-top: 10vh;
				
				@mixin margin($breakpoint, $contentPadding) {
					@media screen and (min-width: $breakpoint) {
						width: calc(100% - #{$contentPadding});
						margin-left: $contentPadding;
					}
				}
				
				@include margin($breakpointL1, $contentPaddingL1);
				@include margin($breakpointL2, $contentPaddingL2);
				@include margin($breakpointL3, $contentPaddingL3);
				@include margin($breakpointL4, $contentPaddingL3);
				@include margin($breakpointL5, $contentPaddingL3);
			}
		}

		.oneColumn {

			margin-left: 10px;
			margin-right: 10px;
			
			@mixin widthAndMargin($breakpoint, $contentWidth) {
				@media screen and (min-width: $breakpoint) {
					width: #{$contentWidth};
					margin-left: calc((100% - #{$contentWidth}) / 2);
					margin-right: calc((100% - #{$contentWidth}) / 2);
				}
			}

			@each $breakpoint, $contentWidth in $breakpointList {
				@include widthAndMargin($breakpoint, $contentWidth);
			}
				
		}
		
	}

}
