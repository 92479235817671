// Better alternative to normalize configuration
sup, sub { 
	position: relative;
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
}
sup {
	bottom: 1ex;
}
sub {
	top: .5ex;
}

// additional declarations
svg {
	vertical-align: bottom;
}
 
div {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
