html,
body {
	height: 100%;
}

html, button, input, select, textarea {
	color: $textColor_0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, img, li, main, menu, nav, ol, section, summary, ul {
	box-sizing: border-box;
}

html {
	background: #ffffff;
	font-size: 100%;
	overflow-x: auto;
	overflow-y: scroll;
	
	@media (min-width: $breakpointL3) {
		font-size: 106.25%;
	}
	
	@media (min-width: $breakpointL4) {
		font-size: 112.5%;
	}
}

body {
	font-family: Muli, 'Helvetica Neue', Arial, sans-serif;
	font-size: 1em;
	line-height: 1.6;
	letter-spacing:.05em;
	color: $textColor_0;
	background: #ffffff;

	#main.test1 {
		font-family: Muli, 'Helvetica Neue', Arial, sans-serif;
		font-size: 1em;
		
		h1 {
			font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;

			@media screen and (max-width: 519px) {
				font-size: 1.8rem;
			}

			font-weight: 600;
			letter-spacing: .3rem;
		}
		
		p {
			font-size: 1.0625rem;
		}
	}
	
	@media (min-width: $breakpointL3) {
		line-height: 1.8;
	}
	
	.pure-g {
		font-family: Raleway, 'Helvetica Neue', Arial, sans-serif;
	}
}

.pure-g [class*="pure-u"] {
	font-family: Raleway, 'Helvetica Neue', Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: $semibold;
}

h1 {
	margin-top: 0;
	font-family: 'Josefin Sans';
	font-size: calc(1.4rem + 3vw);
	line-height: 1.2;
	text-transform: uppercase;
}

h2 {
	margin-top: 3rem;
	font-size: 1.6rem;

	@media screen and (max-width: $breakpointS2 - 1px) {
		font-size: calc(0.9rem + 1vw);
	}

	font-weight: $bold;
	letter-spacing: $letterSpacingH2;
	margin-right: -$letterSpacingH2;
	text-transform: uppercase;

	&:first-child {
		margin-top: 0;
	}
	
}

.subheading {
	font-weight: $light;
	font-size: 0.8rem;
	letter-spacing: $letterSpacingSubheading;
	margin-right: -$letterSpacingSubheading;
	text-transform: uppercase;
	display: inline-block;
	margin-top: 2rem;
	
	@media screen and (max-width: $breakpointS2 - 1px) {

		~ .subheading {
			font-size: 1rem;
		}
	}
}

h3 {
	text-transform: uppercase;
}

h3,
h4,
h5,
p {
	margin: 0;
	font-size: 1rem;
	font-weight: $normal;
	
	@media (min-width: $breakpointL1) {
		font-size: 1rem;
	}

	-moz-hyphens: auto;
	-ms-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	-moz-hyphenate-limit-lines: 2;
	-ms-hyphenate-limit-lines: 2;
	-webkit-hyphenate-limit-lines: 2;
	-moz-hyphenate-limit-chars: 6 3 3;
	-ms-hyphenate-limit-chars: 6 3 3;
	-webkit-hyphenate-limit-chars: 6 3 3;
	-moz-hyphenate-limit-zone: 40px;
	-ms-hyphenate-limit-zone: 40px;
	-webkit-hyphenate-limit-zone: 40px;
}
h3,
h4,
h5,
p:not(:first-child) {
	margin: 1.4rem 0 0;
	font-weight: $normal;
	
	@media (min-width: $breakpointL1) {
		margin-top: 1.6rem;
	}
	@media (min-width: $breakpointL3) {
		margin-top: 1.8rem;
	}
}

/*****	Listen	*****/

#main dl, 
#main menu, 
#main ol, 
#main ul,
#main li.mitAbstand {
	margin: 1.8rem 0 0;
}


/***** Liste mit PDF-Dateien *****/

ul.pdf {
	list-style-type: none;
	margin-left: 3px;
	padding-left: 0;
	li {
		margin-left: 48px;
		text-indent: -48px;
	}
	a {
		background: url("../Icons/link_pdf.png") left 2px no-repeat;
		padding-left: 48px;
	}
}

/*****	Tabellen	*****/

table {
	margin: 1.6rem 0 0;
	empty-cells: show;
}

caption {
	font-weight: $bold;
	text-align: left;
}

th {
	text-align: left;
	vertical-align: top;
}

td {
	text-align: left;
	vertical-align: top;
}

.table {
	display: table;
}

.tableCaption {
	display: table-caption;
}

.tableColGroup {
	display: table-column-group;
}

.tableColumn {
	display: table-column;
}

.tableRow {
	display: table-row;
}

.tableCell {
	display: table-cell;
}

/*****	weitere Block-Elemente	*****/

hr {
	margin-top: 3rem;
	margin-bottom: 3.5rem;
	border-width: 0;
	border-top: 1px solid $textColor_0;
}

/*****	weitere Inline-Elemente	******************************************************/
video,
img {
	max-width: 100%;
	// max-height: 100%;
}

.image img {
	width: 100%;
	height: auto;
	display: block;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

img[loading="lazy"] {
	animation-name: fadeIn;
	animation-duration: $animationStepDuration;
	animation-fill-mode: forwards;
	animation-timing-function: ease;
}

strong, b, .bold {
	font-weight: $bold;
}

/*****	Links	*****/
a {
	color: $linkColor_0;
	border-bottom: 1px solid transparent;
	text-decoration: none;
	transition: color 500ms ease, border 500ms ease;

	&:active, &:focus, &:hover, &:link, &:visited {
		text-decoration: none;
	}

	&:active, &:focus, &:hover {
		color: $linkHoverColor_0;
	}

	&.linkPdf {
		background: url('../Icons/link_pdf.png') left 2px no-repeat;
		padding-left: 36px;
	}
}

ul.linkPdf {
	list-style-type: none;
	padding: 0;
	li {
		background: url("../Icons/link_pdf.png") left 3px no-repeat;
		padding-left: 36px;
	}
}

/*
.linkIn { background:url('../img/link_in.gif') left top no-repeat; color:#4d4d4d; }
.linkOut { background:url('../img/link_out.gif') left top no-repeat; color:#487c3b; padding-left:18px; }
.linkDown { background:url('../img/link_down.gif') left top no-repeat; color:#487c3b; padding-left:18px; }
.linkPopup { background:url('../img/link_popup.gif') left top no-repeat; color:#487c3b; padding-left:18px; }
*/

blockquote {
	margin: 1.6rem 0;
	padding-left: 8px;
	border-left: 1em solid #e3e3e3;
	font-style: italic;
}

q {
	quotes: '\201E' '\201D' '\201A' '\2019';
	&:before {
		content: '\201E';
	}
	&:after {
		content: '\201D';
	}
	q {
		&:before {
			content: '\201A';
		}
		&:after {
			content: '\2019';
		}
	}
}

.normal {
	font-weight: $normal;
}

.bold {
	font-weight: $bold;
}

.lowercase {
	text-transform: lowercase;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

.alignLeft {
	text-align: left;
}

.alignCenter {
	text-align: center;
}

.alignRight {
	text-align: right;
}

.alignBlock {
	text-align: justify;
}

.floatLeft {
	float: left;
}

.floatRight {
	float: right;
}

.flex {
	display: flex;
	
	> * {
		flex: 0 1 auto;
	}
}

.flexCol {
	display: flex;
	flex-direction: column;
	
	> * {
		flex: 0 1 auto;
	}
}

.flexApart {
	justify-content: space-between;
}

.marginT0 {
	margin-top: 0 !important;
}
.marginT0S1 {
	@media screen and(max-width: $breakpointS2 - 1px) {
		margin-top: 0 !important;
	}
}

.marginH0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.marginL0 {
	margin-left: 0 !important;
}

.marginR0 {
	margin-right: 0 !important;
}
.marginR0S1 {
	@media screen and(max-width: $breakpointS2 - 1px) {
		margin-right: 0 !important;
	}
}

.displayBlock {
	display: block !important;
}

.displayInlineBlock {
	display: inline-block !important;
}

.noHyphens {
	-moz-hyphens: none;
	-ms-hyphens: none;
	-webkit-hyphens: none;
	hyphens: none;
}

.hide,
.hideBlockMobileOnly,
.hideBlockDesktopOnly,
.hideInlineBlockMobileOnly,
.hideInlineBlockDesktopOnly,
.hideInlineMobileOnly,
.hideInlineDesktopOnly {
	display: none !important;
}
@media screen and (max-width: $breakpointL1) {
	.hideBlockDesktopOnly {
		display: block;
	}
	.hideInlineBlockDesktopOnly {
		display: inline-block;
	}
	.hideInlineDesktopOnly {
		display: inline;
	}
}
@media screen and (min-width: $breakpointL1) {
	.hideBlockMobileOnly {
		display: block;
	}
	.hideInlineBlockMobileOnly {
		display: inline-block;
	}
	.hideInlineMobileOnly {
		display: inline;
	}
}

@media screen and (max-width: $breakpointL1) {
	.mobileOrder1 {
		order: 1;
	}
	.mobileOrder2 {
		order: 2;
	}
}

.borderBox {
	box-sizing: border-box;
}

@media screen and (min-width: $breakpointL1) {
	.columns2 {
		columns: 2;
	}
}

.order1 {
	order: 1;
}

.order2 {
	order: 2;
}

.text > h3:first-child {
	margin-top: 0;
}

.breakAfter {
	break-after: column;
}

.noBreakAfter {
	break-after: avoid-column;
}

.cf {
	display: table;
	content: '';
	clear: both;
}

::-moz-selection {
	color: #fff;
	background: $primaryColor;
}
::selection {
	color: #fff;
	background: $primaryColor;
}

