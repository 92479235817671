/* alternative to pure base or as an addition */
@import 'external/normalize-v8.0.0';

/* altoplan standards */
@import 'altoplan/normalize-adjustments';

/* SASS variables */
@import 'layout/_00_config';

/* SITE LAYOUT */
@import 'layout/_04_typefaces';
@import 'layout/_07_basics';
@import 'layout/_10_menus';
@import 'layout/_14_structure';
@import 'layout/_17_general';
@import 'layout/_headslider';
@import 'layout/_custom';
@import 'layout/_videogrid';
@import 'layout/_videocontrols';